import {Component, Vue} from 'vue-property-decorator';

@Component({
    name: 'tag-manager'
})
export default class TagManager extends Vue {
    protected pushDataLayout(name) {
        window.dataLayer?.push({
            event: 'Custom Event',
            eventName: name
        })
    }
    protected pushFacebookPixelLead() {
        window.dataLayer?.push({
            event: 'Facebook Pixel',
            facebookPixelLead: true
        })
    }
}
